import api from "@/services/Api";
import authHeader from "@/services/AuthHeader";

export default {
  getShippingsDetail() {
    return api.get("sellers/shipping", { headers: authHeader() });
  },
  updateShippingsDetail(data) {
    return api.post("sellers/shipping", data, { headers: authHeader() });
  },
};
