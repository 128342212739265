<template>
  <v-row class="shipping-to-section align-center my-6 m-0">
    <div class="d-flex flex-wrap pa-0" style="row-gap: 21px">
      <v-col cols="12" sm="12" md="3" class="pa-0" style="width: 32.7%">
        <PSelect
          style="
            border: 0.1rem solid rgb(201, 204, 207) !important;
            border-radius: 4px !important;
          "
          :placeholder="newShippingTo.country"
          :options="listOfCountries"
          @change="SetShipToCountry($event)"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="3"
        class="py-0 shipping-px-6"
        style="width: 32.7%"
      >
        <PSelect
          style="
            border: 0.1rem solid rgb(201, 204, 207) !important;
            border-radius: 4px !important;
          "
          :placeholder="newShippingTo.delivery_days"
          :options="[
            { label: '1 - 3 days', value: '1 - 3 days' },
            { label: '3 - 7 days', value: '3 - 7 days' },
            { label: '7 - 14 days', value: '7 - 14 days' },
          ]"
          v-model="newShippingTo.delivery_days"
        />
      </v-col>
      <v-col cols="12" sm="12" md="3" class="last_input pa-0 shipping-pr-6">
        <PSelect
          style="
            border: 0.1rem solid rgb(201, 204, 207) !important;
            border-radius: 4px !important;
          "
          :options="currency"
          v-model="newShippingTo.currency"
        />
      </v-col>
      <v-col cols="12" sm="12" md="2" class="last_input pa-0">
        <PTextField
          v-model="newShippingTo.price"
          id="shipping_to_cost"
          type="number"
        />
      </v-col>
      <div class="delete_btn">
        <button
          class="Polaris-Button Polaris-Button--outline Polaris-Button--monochrome"
          type="button"
          @click="$emit('removeshipto')"
        >
          <PIcon source="DeleteMinor" />
        </button>
      </div>
    </div>
  </v-row>
</template>

<script>
// import CommonData from "@/services/CommonData";
import { currency } from "@/utils/currency";
export default {
  props: {
    shippingTo: {
      type: Object,
      default: () => ({}),
    },
    countries: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      newShippingTo: {},
      shippingtoCities: [],
      shipping_to_country: {},
      shipping_to_city: {},
      currency: currency,
    };
  },
  async created() {
    this.newShippingTo = JSON.parse(JSON.stringify(this.shippingTo));
  },
  computed: {
    listOfCountries() {
      const listOfCountries = this.countries.map((country) => {
        return { label: country.name, value: country.id };
      });
      return listOfCountries;
    },
  },
  methods: {
    SetShipToCountry(countryID) {
      const selectedCountry = this.countries.find(
        (country) => country.id === countryID
      );
      if (selectedCountry) {
        this.newShippingTo.country = selectedCountry.name;
        this.newShippingTo.countryISO2 = selectedCountry.iso2;
      }
    },
  },
  watch: {
    newShippingTo: {
      handler(newShippingTo) {
        this.$emit("shippingtochange", newShippingTo);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.shipping-to-section {
  margin-bottom: 44px !important;
  @include md {
    margin-bottom: 0 !important;
    padding-bottom: 24px;
    border-bottom: 1px solid #d2d4d5;
  }
  .shipping-pr-6 {
    padding-right: 24px !important;
    @include lg {
      padding: 0 !important;
    }
  }
  .shipping-px-6 {
    padding: 0 24px !important;
    @include lg {
      padding: 0 !important;
    }
  }
}
.delete_btn {
  background: #f2f4f7;
  position: relative;
  color: #5c5f62;
  border: unset;
  margin: 0;
  border-radius: 5px;
  margin-left: 21px;
  @include lg {
    margin-left: 0 !important;
  }
  button {
    border: unset !important;
    box-shadow: unset !important;
  }
  // top: 20px;
  @media screen and (max-width: 768px) {
    margin: 0 auto;
    top: 0;
  }
  @media screen and (min-width: 529px) and (max-width: 575px) {
    top: 7px;
  }
}
.last_input {
  width: 12.7% !important;
  @media screen and (min-width: 769px) and (max-width: 958px) {
    width: 34% !important;
  }
  @media screen and (max-width: 992px) {
    // todo
    width: 12.7% !important;
  }
  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}
</style>
